<template>
	<v-dialog
		origin="top center"
		v-model="dialog"
		persistent
		fullscreen
		hide-overlay
		scrollable
		content-class="common-dialog"
		:max-width="dialogWidth"
		transition="dialog-top-transition"
	>
		<v-card>
			<v-card-title
				v-if="$slots.title"
				class="create-dialog-header custom-border-bottom grey lighten-3"
			>
				<h3>
					<slot name="title"></slot>
				</h3>
				<v-spacer></v-spacer>
				<div v-if="$slots.additional" class="mr-5">
					<slot name="additional"></slot>
				</div>
				<div>
					<v-tooltip bottom content-class="custom-bottom-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click.prevent.stop="closeDialog()"
								color="blue darken-4 cursor-pointer"
								>mdi-close</v-icon
							>
						</template>
						<span>Close</span>
					</v-tooltip>
				</div>
			</v-card-title>
			<v-card-text
				class="scrollable-section create-page"
				ref="scrollable_section"
				v-if="$slots.body"
				:class="{ 'px-5 py-3': !dense, 'px-0 py-0': dense }"
			>
				<slot name="body"></slot>
			</v-card-text>
			<v-divider class="my-0"></v-divider>
			<v-card-actions v-if="$slots.action" class="grey lighten-3">
				<v-spacer></v-spacer>
				<v-btn v-if="false" @click="closeDialog()" depressed tile> Close </v-btn>
				<slot name="action"></slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
// import "@/core/event-bus/scroll.js";
export default {
	props: {
		dialog: {
			type: Boolean,
			required: true,
			default: false,
		},
		dense: {
			type: Boolean,
			required: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
	},
	methods: {
		closeDialog() {
			this.$emit("close", true);
			setTimeout(() => {
				this.goBack();
			}, 300);
		},
		handleScroll: function (event) {
			const offsetTop = event.target.offsetTop;
			const scrollTop = event.target.scrollTop;
			if (scrollTop > offsetTop) {
				this.$emit("scroll", scrollTop);
			}
		},
		init() {
			const el = this.$refs.scrollable_section;
			el.addEventListener("scroll", this.handleScroll);
		},
	},
	beforeUnmount() {
		const el = this.$refs.scrollable_section;
		el.removeEventListener("scroll", this.handleScroll);
	},
	mounted() {
		this.init();
	},
};
</script>
<style scoped>
.v-dialog__content {
	align-items: start;
}
.v-application .create-dialog-header {
	border-top: 8px solid #f57c00 !important;
	border-radius: 0 !important;
	color: #0d47a1 !important;
	line-height: 1.11em !important;
	font-size: 18px !important;
	font-weight: 600 !important;
}
.create-dialog-header h3 {
	margin-bottom: 0;
}
.v-card__subtitle,
.v-card__text {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.475rem;
	letter-spacing: 0.0071428571em;
}
</style>
