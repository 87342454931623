<template>
	<span class="custom-autocomplete1">
		<v-autocomplete
			:id="id"
			:label="label"
			:loading="loading"
			:disabled="disabled"
			:clearable="clearable"
			:items="items"
			:rules="rules"
			:prepend-inner-icon="prependInnerIcon"
			:append-icon="appendIcon"
			:append-outer-icon="appendOuterIcon"
			:item-text="itemText"
			:item-value="itemValue"
			:hide-details="hideDetails"
			:return-object="returnObject"
			:multiple="multiple"
			:autofocus="autofocus"
			:readonly="readonly"
			:reverse="reverse"
			outlined
			class="pt-0"
			:class="{
				...contentClass,
				'mt-3': !hideTopMargin,
				'dense-autocomplete': dense,
				'time-picker-autocomplete': timePicker,
			}"
			auto-grow
			:menu-props="{ maxHeight: 500 }"
			v-model="autocompleteinput"
			:placeholder="getPlaceholder()"
			v-on:click:clear="$emit('click:clear', true)"
			v-on:change="$emit('change', autocompleteinput)"
			v-on:click:append-outer="$emit('click:append-outer', true)"
			chips
			small-chips
		>
			<template v-slot:selection="{ item, index, select }">
				<v-chip
					v-bind="item.attrs"
					:small="dense"
					:input-value="item.selected"
					close
					v-if="index < selectionChip"
					@click="select"
					@click:close="remove(item)"
				>
					<v-avatar left>
						<v-img v-if="item.image" max-height="15px" max-width="15px" :src="item.image"></v-img>
						<img v-else :src="$assetURL('media/users/default.jpg')" class="row-not-found-image" />
					</v-avatar>
					<span class="blue-grey-darken-2"> {{ item.text }}</span>
				</v-chip>
				<span class="ml-2 cyan--text" v-if="index === selectionChip"
					>({{
						autocompleteinput.slice(selectionChip + 1, autocompleteinput.length).length + 1
					}}
					more)</span
				>
			</template>
			<template v-slot:item="data">
				<template v-if="typeof data.item !== 'object'">
					<v-list-item-content v-text="data.item"></v-list-item-content>
				</template>
				<template>
					<v-list-item-avatar style="padding: 0px" size="20" dense>
						<v-img
							v-if="data.item.image"
							:src="data.item.image"
							max-height="15px"
							max-width="15px"
						></v-img>
						<v-img v-else :src="$assetURL('media/users/default.jpg')" class="row-not-found-image" />
					</v-list-item-avatar>
					<v-list-item-content style="padding: 0px" dense>
						<v-list-item-title style="padding: 0px" v-html="data.item.text"></v-list-item-title>
					</v-list-item-content>
				</template>
			</template>

			<template v-slot:no-data>
				<v-list-item class="py-2">
					<v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
				</v-list-item>
			</template>
		</v-autocomplete>
	</span>
</template>
<script>
import { cloneDeep } from "lodash";

export default {
	name: "autocomplete-input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Object, Number, String, Array],
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		contentClass: {
			type: Object,
			default: null,
		},
		prependInnerIcon: {
			type: String,
			default: null,
		},
		appendIcon: {
			type: String,
			default: "mdi-menu-down",
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		itemText: {
			type: String,
			default: "text",
		},
		itemValue: {
			type: String,
			default: "value",
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		reverse: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		selectionChip: {
			type: Number,
			default: 5,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				this.autocompleteinput = value;
			},
		},
	},
	data() {
		return {
			autocompleteinput: null,
			menuProps: { maxHeight: 500 },
		};
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
		remove(item) {
			if (this.multiple) {
				let cloneItem = cloneDeep(this.value);
				let index = null;
				index = cloneItem.findIndex((ele) => {
					return ele === item.value;
				});
				cloneItem.splice(index, 1);
				this.$emit("remove", cloneItem);
			} else {
				this.$emit("remove", null);
			}
		},
	},
	mounted() {
		this.autocompleteinput = this.value;
		if (this.dense) {
			this.menuProps.contentClass = "dense-autocomplete-list";
		}
		if (this.timePicker) {
			this.menuProps.contentClass = "time-picker-autocomplete-list";
		}
	},
};
</script>
<style>
.v-list {
	padding: 0px 0 !important;
}
</style>
